import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { SizeModel } from '../../../core/shared';
import { StorageService } from '../../../core/shared/services/storage.service';

export const DEFAULT_SIZES: SizeModel = {
  level1: '1050',
  level2: '850',
  level3: '650',
  level4: '450',
  level5: '250'
};

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  value: any;

  levelOptions: string[] = ['12.5mm', '10.5mm', '8.5mm', '6.5mm'];

  slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  rangeForm: FormGroup;

  constructor(private fb: FormBuilder, private alertController: AlertController, private storageService: StorageService, private loadingController: LoadingController) {
    this.rangeForm = this.fb.group({
      level1: [''],
      level2: [''],
      level3: [''],
      level4: [''],
      level5: [''],
    });
    this.getSizes();
  }



  getSizes() {
    this.storageService.getSizesPreferences().then((sizes) => {
      if (!sizes) {
        return this.setDefaultSizes();
      }
      this.rangeForm.patchValue(sizes);
    });
  }

  setDefaultSizes() {
    this.rangeForm.patchValue(DEFAULT_SIZES);
    this.storageService.setSizesPreferences(DEFAULT_SIZES);
  }

  async saveToStorage() {


    const loading = await this.loadingController.create({
      message: 'Salvando...'
    });
    await loading.present();

    setTimeout(() => {
      this.storageService.setSizesPreferences(this.rangeForm.value);
      loading.dismiss();
    }, 300);

  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    });
    await loading.present();
  }

  async resetToDefault() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar',
      message: 'Deseja realmente <strong>resetar para o padrão</strong> os ajustes de tamanho de fonte? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Resetar',
          handler: () => {
            this.setDefaultSizes();
          }
        }
      ]
    });

    await alert.present();
  }



}
